import { combineReducers } from 'redux';
import { reduxSoldierMiddleware } from 'redux-soldier';
import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'features/login/state/reducer';
import providerReducer from 'features/common/state/reducer';
import resultReducer from 'features/result/state/reducer';
import navigatorReducer from 'features/navigation/state/reducer';
import searchReducer from 'features/search/state/reducer';
import evaluationReducer from 'features/evaluation/state/reducer';
import downloadReducer from 'features/download/state/reducer';
import weightsReducer from 'features/weights/state/reducer';
import systemDateReeducer from 'features/footer/state/reducer';
import homeReducer from 'features/home/state/reducer';
import countReducer from 'features/count/state/reducer';
import censusReducer from 'features/census/state/reducer';
import storageSession from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import appReducer from './state/reducer';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whilelist: ['evaluation'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  provider: providerReducer,
  app: appReducer,
  result: resultReducer,
  navigator: navigatorReducer,
  search: searchReducer,
  evaluation: evaluationReducer,
  download: downloadReducer,
  weights: weightsReducer,
  systemDate: systemDateReeducer,
  home: homeReducer,
  count: countReducer,
  census: censusReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }).concat(reduxSoldierMiddleware),
});

export const persistor = persistStore(store);

export default store;
