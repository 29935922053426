import { Grid, Divider, Stack } from '@mui/material';
import { DIVIDER_SOLID, VIEW_ALL_PLAN, HOME_PAGE_TITLE_DARK } from 'common/Constants';
import { useSelector } from 'react-redux';
import React, { useState, forwardRef, useEffect } from 'react';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import SortIndicator from 'common/components/SortIndicator';
import TableCellHeader from 'common/components/TableCellHeader';
import { CustomHomePageTheme, HeaderRow } from 'features/common/Style';
import store from 'app/store';
import TableRow from './tableRow';
import { setPrevSelectedAllPlanSort } from '../state/reducer';

export default function ViewAllPlansTable() {
  const networks = useSelector((state) => state.provider.networks);
  const networkList = [...networks];
  const [sortConfig, setSortConfig] = useState({ key: 'label', direction: 'asc' });
  const prevSelectedAllPlanSort = useSelector((state) => state.home.prevSelectedAllPlanSort);
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    store.dispatch(setPrevSelectedAllPlanSort({ key, direction }));
  };
  const sortedNetworks = React.useMemo(() => {
    if (sortConfig.key === 'label' && sortConfig.direction === 'asc') {
      networkList.sort((a, b) => (a.label.localeCompare(b.label)));
    } else if (sortConfig.key === 'label' && sortConfig.direction === 'desc') {
      networkList.sort((a, b) => (b.label.localeCompare(a.label)));
    } else if (sortConfig.key === 'hospitalCount' && sortConfig.direction === 'asc') {
      networkList.sort((a, b) => (a.hospitalCount - b.hospitalCount));
    } else if (sortConfig.key === 'hospitalCount' && sortConfig.direction === 'desc') {
      networkList.sort((a, b) => (b.hospitalCount - a.hospitalCount));
    }
    return networkList;
  }, [networkList, sortConfig]);

  useEffect(() => {
    if (prevSelectedAllPlanSort != null) {
      setSortConfig(prevSelectedAllPlanSort);
    }
  }, []);

  const customAppTheme = useTheme();
  const pageTheme = CustomHomePageTheme(customAppTheme);

  return (

    <Grid item xs={12}>
      <Stack>
        <Grid
          container
          sx={{ alignItems: 'flex-end', flexDirection: 'row', display: 'flex', flexWrap: 'nowrap' }}
        >
          <Grid item xs={6}>
            <ThemeProvider theme={pageTheme}>
              <TableCellHeader
                key="label"
                label="Plan"
                sortDirection={sortConfig.direction}
                active={sortConfig.key === 'label'}
                direction={sortConfig.direction}
                onClick={() => handleSort('label')}
                main
                headerColor={HOME_PAGE_TITLE_DARK}
                IconComponent={forwardRef((prop, ref) => <SortIndicator {...prop} ref={ref} height="24px" />)}
                sx={{ alignItems: 'flex-end' }}
              />
            </ThemeProvider>
          </Grid>
          <Grid item xs={2}>
            <HeaderRow>Plan Type</HeaderRow>
          </Grid>
          <Grid item xs={2}>
            <HeaderRow>Level</HeaderRow>
          </Grid>
          <Grid item xs={2}>
            <ThemeProvider theme={pageTheme}>
              <TableCellHeader
                key="hospitalCount"
                label="Hospital Count"
                sortDirection={sortConfig.direction}
                active={sortConfig.key === 'hospitalCount'}
                direction={sortConfig.direction}
                onClick={() => handleSort('hospitalCount')}
                headerColor={HOME_PAGE_TITLE_DARK}
                IconComponent={forwardRef((prop, ref) => <SortIndicator {...prop} ref={ref} marginRight="15px" height="24px" />)}
                sx={{ alignItems: 'flex-end' }}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
        <Grid item>
          <Divider
            orientation="horizontal"
            sx={{
              height: '0.25rem',
              borderBottomWidth: '2px',
              borderBottomColor: DIVIDER_SOLID,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {Array.isArray(sortedNetworks) && sortedNetworks.map((n, idx) => (
            <TableRow
              key={n.network}
              networkRowData={n}
              index={idx}
              dataLength={networks.length}
              selectedTab={VIEW_ALL_PLAN}
            />
          ))}
        </Grid>
      </Stack>
    </Grid>
  );
}
