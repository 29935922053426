import { Tabs, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxStyled = styled(Box)(({ theme }) => ({
  '& > button': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderRadius: '1rem',
    height: '1.5rem',
    minHeight: '1.5rem',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  '& > button + button': {
    marginLeft: '1rem',
  },
  marginBottom: '0.5rem',
}));

export const BoxHeaderStyled = styled(Box)(() => ({
  marginBottom: '1rem',
}));

export const TabsStyled = styled(Tabs)(() => ({
  '& button': {
    textTransform: 'none',
  },
}));
