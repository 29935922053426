import { useState, useEffect, useMemo } from 'react';
import { getComparator, stableSort } from 'common/util/commonUtil';

export default function useCustomSort({ initialData, shouldSort }) {
  const [sortedData, setSortedData] = useState(initialData);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('');
  const [activeSort, setActiveSort] = useState(true);

  const handleSortState = (property) => {
    if (orderBy === property) {
      if (activeSort && order === 'asc') {
        setOrder('desc');
      } else if (activeSort && order === 'desc') {
        setActiveSort(false);
        setOrderBy('');
        setSortedData(initialData);
      } else {
        setOrder('asc');
        setActiveSort(true);
      }
    } else {
      setOrder('asc');
      setOrderBy(property);
      setActiveSort(true);
    }
  };

  const sortedRows = useMemo(
    () =>
      stableSort(initialData, getComparator(order, orderBy)),
    [order, orderBy, shouldSort],
  );

  useEffect(() => {
    setSortedData(sortedRows);
  }, [sortedRows]);

  return {
    sortedData, handleSortState, setOrder, order, setOrderBy, orderBy, setActiveSort, activeSort,
  };
}
