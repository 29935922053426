import React, { useEffect } from 'react';
import store from 'app/store';
import { getEvaluationResultDetails } from 'features/evaluation/state/service';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import DataUsabilityRatingLink from 'common/components/DataUsabilityRatingLink';
import { Grid } from '@mui/material';
import NetworkSummaries from './NetworkSummaries';
import PopulationResultDetail from './PopulationResultDetail';

const ParagraphStyled = styled('div')(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: '500',
  paddingLeft: '20px',
}));
export default function StudyResults({ evalId }) {
  const resultDetails = useSelector((state) => state.evaluation.resultDetails
    .populationResultDto);
  const isResultLoading = useSelector((state) => state.evaluation.isResultLoading);

  useEffect(() => {
    store.dispatch(getEvaluationResultDetails(
      { evaluationId: evalId },
    ));
  }, [evalId]);
  return (
    <>
      <NetworkSummaries summaries={resultDetails?.networkSummaries} />
      <Grid item xs={12} mt="-1rem" mb=".8rem">
        <DataUsabilityRatingLink />
      </Grid>
      { resultDetails?.populationResultForMSAs
        && (Object.keys(resultDetails?.populationResultForMSAs).length > 0)
        ? <PopulationResultDetail resultDetails={resultDetails} />
        : (
          !isResultLoading && (
          <ParagraphStyled>
            No results to display
          </ParagraphStyled>
          )
        ) }
    </>
  );
}
