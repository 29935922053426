import React from 'react';
import { Typography, Table, TableHead, TableRow } from '@mui/material';
import { NetworkSubColumnCell } from './PopulationStyles';

export default function NetworkResultsHeader() {
  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow>
          <NetworkSubColumnCell width="35%" align="center">
            <Typography variant="paraHeader">% Medicare</Typography>
          </NetworkSubColumnCell>
          <NetworkSubColumnCell width="30%" align="center">
            <Typography variant="paraHeader">NPI Count</Typography>
          </NetworkSubColumnCell>
          <NetworkSubColumnCell width="35%" align="center">
            <Typography variant="paraHeader">Data Usability</Typography>
          </NetworkSubColumnCell>
        </TableRow>
      </TableHead>
    </Table>
  );
}
