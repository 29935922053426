import React from 'react';
import { Typography, TableRow, TableBody, IconButton, Collapse, TableCell, Table, TableHead } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import NetworkResult from './NetworkResult';
import PopulationCategory from './PopulationCategory';
import {
  BorderTableCell,
  PopStudyTableCell,
  TableCellNetworkValues, TableRowStyled, TypographyMsaStyledName,
} from './PopulationStyles';

export default function PopulationMSA({ msa, msaPopulationResult, networkSummaries }) {
  const isMsaRecord = (record) => (record.billingClass === null && record.category === null);
  const msaLevelPopulation = msaPopulationResult.find(isMsaRecord);
  const isCategoryRecord = (record) => (record.billingClass !== null && record.category !== null);
  const categoryLevelPopulations = msaPopulationResult.filter(isCategoryRecord);
  const [open, setOpen] = React.useState(false);
  return (
    <TableBody>
      <TableRowStyled>
        <BorderTableCell>
          <Table sx={{ tableLayout: 'fixed' }}>
            <TableHead />
            <TableBody>
              <TableRow border="true">
                <TableCell align="left" sx={{ padding: '0', width: '5%' }}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    sx={{ padding: '0', visibility: categoryLevelPopulations.length > 0 ? 'visible' : 'hidden' }}
                  >
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </TableCell>
                <TableCell align="left" sx={{ padding: '0px 16px 0px 6px', width: '40%' }}>
                  <TypographyMsaStyledName variant="h6">{msa.msaName}</TypographyMsaStyledName>
                </TableCell>
                <PopStudyTableCell align="right">
                  <Typography>{`${msa.msaPopulation.toLocaleString('en-US')}`}</Typography>
                </PopStudyTableCell>
                <PopStudyTableCell align="right">
                  <Typography>{`${msa.populationPercent}%`}</Typography>
                </PopStudyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </BorderTableCell>
        {(networkSummaries || []).map((item) => (
          <TableCellNetworkValues
            key={item.network.code}>
            <NetworkResult
              networkResult={msaLevelPopulation.populationResultForNetwork[item.network.code]}
            />
          </TableCellNetworkValues>
        ))}
      </TableRowStyled>
      <TableRow>
        <PopStudyTableCell
          colSpan={5}
          sx={{ paddingRight: '0px', paddingLeft: '0px !important' }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <PopulationCategory
              msa={msa}
              categoryLevelPopulations={categoryLevelPopulations}
              networkSummaries={networkSummaries} />
          </Collapse>
        </PopStudyTableCell>
      </TableRow>
    </TableBody>
  );
}
