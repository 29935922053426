import React from 'react';
import { Table, TableBody } from '@mui/material';
import { capitalizePhrase } from 'common/util/commonUtil';
import NetworkResult from './NetworkResult';
import HeaderGroup from './HeaderGroup';
import { BorderTableCell, TableCellNetworkValues, TableRowStyled, TypographyStyledName } from './PopulationStyles';

export default function PopulationCategory({ msa, categoryLevelPopulations, networkSummaries }) {
  const { msaNo } = msa;
  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <HeaderGroup />
      <TableBody>
        {(categoryLevelPopulations).map((categoryLevel) => (
          <TableRowStyled
            key={`${msaNo} ${categoryLevel.category} ${categoryLevel.billingClass}`}
            sx={{ backgroundColor: (theme) => (theme.palette.background.white) }}>
            <BorderTableCell>
              <TypographyStyledName variant="h6">
                {categoryLevel.billingClass === 'professional'
                  ? `${capitalizePhrase(categoryLevel.billingClass)}`
                  : `${categoryLevel.category} ${capitalizePhrase(categoryLevel.billingClass)}`}
              </TypographyStyledName>
            </BorderTableCell>
            {(networkSummaries || []).map((item, idx) => (
              <TableCellNetworkValues
                idx={idx}
                key={`${msaNo} ${item.network.code}`}
              >
                <NetworkResult
                  networkResult={categoryLevel.populationResultForNetwork[item.network.code]}
                />
              </TableCellNetworkValues>
            ))}
          </TableRowStyled>
        ))}
      </TableBody>
    </Table>
  );
}
