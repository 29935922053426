import React from 'react';
import { Typography, Table, TableHead, TableRow } from '@mui/material';
import { PopStudyTableCell } from './PopulationStyles';

export default function MsaResultsHeader() {
  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow>
          <PopStudyTableCell width="50%" align="center">
            <Typography variant="paraHeader">Name</Typography>
          </PopStudyTableCell>
          <PopStudyTableCell width="25%" align="center">
            <Typography variant="paraHeader">Population Count</Typography>
          </PopStudyTableCell>
          <PopStudyTableCell width="25%" align="center">
            <Typography variant="paraHeader">Population %</Typography>
          </PopStudyTableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
}
