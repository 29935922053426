import React from 'react';
import { Typography, TableRow, Table, TableHead, TableBody } from '@mui/material';
import HorizontalRatingBar from 'common/components/HorizontalRatingBar';
import { POPULATION_NETWORK_RESULT } from 'common/Constants';
import { PopStudyTableCell } from './PopulationStyles';

export default function NetworkResult({ networkResult }) {
  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow border="true">
          <PopStudyTableCell width="35%" />
          <PopStudyTableCell width="30%" />
          <PopStudyTableCell width="35%" />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow border="true">
          <PopStudyTableCell align="right">
            <Typography>
              {networkResult?.percentMedicare}
              {networkResult?.percentMedicare ? '% ' : null}
            </Typography>
          </PopStudyTableCell>
          <PopStudyTableCell align="right">
            <Typography>{networkResult?.npiCount?.toLocaleString('en-US')}</Typography>
          </PopStudyTableCell>
          <PopStudyTableCell align="left" padding="none">
            <Typography marginLeft=".2rem">
              {
                networkResult?.usabilityRating >= 0
                && (
                  <HorizontalRatingBar
                    rating={networkResult.usabilityRating}
                    source={POPULATION_NETWORK_RESULT} />
                ) }
            </Typography>
          </PopStudyTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
