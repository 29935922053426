import React, { useState } from 'react';
import { TableContainer, TableHead, Grid, Typography } from '@mui/material';
import { SHOW_ALL_RESULT } from 'common/Constants';
import MultiSelect from 'common/components/MultiSelect';
import PopulationMSA from './PopulationMSA';
import { StudyResultsTable, TableCellNetworkStyledHeader, TableRowStyled, TypographyNetworkStyledName } from './PopulationStyles';
import HeaderGroup from './HeaderGroup';
import NetworkResultsHeader from './NetworkResultsHeader';
import MsaResultsHeader from './MsaResultsHeader';

export default function PopulationResultDetail({ resultDetails }) {
  const networkSummaries = resultDetails?.networkSummaries || [];
  const msaResults = resultDetails?.populationResultForMSAs || [];
  const msaObjectResults = Object.keys(msaResults);
  const [filteredMsa, setFilteredMsa] = useState(msaObjectResults);

  const msaDropdownList = Object.keys(msaResults).map((msaRecord) => {
    const parsedMsa = JSON.parse(msaRecord);
    return {
      id: parsedMsa.msaNo,
      label: `${parsedMsa.msaName}   ${parsedMsa.msaPopulation.toLocaleString('en-US')} ppl ${parsedMsa.populationPercent} %`,
      value: parsedMsa.msaName,
    };
  });

  const handleMsaOnChange = (selectedMsas) => {
    if (selectedMsas.some((msa) => msa.label === SHOW_ALL_RESULT)) {
      setFilteredMsa(msaObjectResults);
    } else {
      setFilteredMsa(msaObjectResults.filter((msa) =>
        selectedMsas.some((selectedMsa) => JSON.parse(msa).msaName === selectedMsa.value)));
    }
  };

  const [selectedOptions, setSelectedOptions] = useState(msaDropdownList);

  const setMsaOptions = (value) => {
    setSelectedOptions(value);
    handleMsaOnChange(value);
  };

  const handleToggleOption = (value) => {
    setMsaOptions(value);
  };
  const handleClearOptions = () => {
    setMsaOptions([]);
  };

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setMsaOptions(msaDropdownList);
    } else {
      handleClearOptions();
    }
  };

  return (
    <>
      <Grid container item xs={3}>
        <MultiSelect
          items={msaDropdownList}
          selectAllLabel="Select All"
          limitTags={3}
          onChange={(selectedMsas) => {
            handleMsaOnChange(selectedMsas);
          }}
          selectedOptions={selectedOptions}
          onToggleOption={handleToggleOption}
          onClearOptions={handleClearOptions}
          onSelectAll={handleSelectAll}
          allLabel={"All MSA's"}
          label={`Filtered MSA's: ${selectedOptions.length} of ${msaDropdownList.length}`}
        />
      </Grid>
      <Grid container item>
        <Typography>Only MSAs with available study data can be expanded.</Typography>
      </Grid>
      <TableContainer sx={{
        maxHeight: (window.innerHeight - 400),
        minHeight: (window.innerHeight - 650),
        marginLeft: '16px',
        marginTop: '24px',
      }}>
        <StudyResultsTable stickyHeader aria-label="collapsible table" sx={{ tableLayout: 'fixed' }}>
          <HeaderGroup />
          <TableHead>
            <TableRowStyled>
              <TableCellNetworkStyledHeader>
                <TypographyNetworkStyledName variant="h6">
                  MSA
                </TypographyNetworkStyledName>
                <MsaResultsHeader />
              </TableCellNetworkStyledHeader>
              {(networkSummaries || []).map((item) => (
                <TableCellNetworkStyledHeader
                  key={`${item.network.code}`}>
                  <TypographyNetworkStyledName variant="h6">{item.network.label}</TypographyNetworkStyledName>
                  <NetworkResultsHeader />
                </TableCellNetworkStyledHeader>
              ))}
            </TableRowStyled>
          </TableHead>
          {filteredMsa
            .map((msa) => (
              <PopulationMSA
                key={JSON.parse(msa).msaNo}
                msa={JSON.parse(msa)}
                msaPopulationResult={msaResults[msa]}
                networkSummaries={networkSummaries}
              />
            ))
          }
        </StudyResultsTable>
      </TableContainer>
    </>
  );
}
