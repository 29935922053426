import React, { useEffect } from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { grey } from '@mui/material/colors';
import Footer from 'features/footer/footer';
import AppBar, { DrawerHeader } from 'common/components/navigatorComponents';
import store from 'app/store';
import { getAllPlanType, getNetworks } from 'features/common/state/service';
import { getCurrentUser } from 'features/login/state/service';
import bstLogo from 'asset/bst-logo.png';
import LogoutConfirmDialog from 'features/login/components/logoutConfirmDialog';
import RoutePath from 'app/router/RoutePath';
import { resetJwt, resetUser } from 'features/login/state/reducer'; // PAN-63
import SideBar from './components/sideBar';

export default function Navigator() {
  const permissions = useSelector((state) => state.auth.authorities);
  const navigate = useNavigate();

  const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);

  const handleSignOut = () => {
    localStorage.clear();
    store.dispatch(resetJwt());
    store.dispatch(resetUser());
    setLogoutDialogOpen(true);
    navigate(RoutePath.signout.path);
  };

  useEffect(() => {
    if (!permissions) {
      store.dispatch(getCurrentUser());
    }
    // loading static data once for all components.
    store.dispatch(getNetworks());
    store.dispatch(getAllPlanType());
  }, []);

  return (
    <>
      <LogoutConfirmDialog open={logoutDialogOpen} setOpen={setLogoutDialogOpen} />
      <Box display="flex">
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <img src={bstLogo} alt="BST" className="app-logo" height="40" />
            <Box flexGrow={1} />
            <ListItemButton
              sx={{
                color: grey[600],
                maxWidth: '130px',
              }}
              onClick={() => handleSignOut()}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 'auto',
                  justifyContent: 'center',
                }}
              />
              <ListItemText
                primary="Sign Out"
              />
              <LogoutIcon />
            </ListItemButton>
          </Toolbar>
        </AppBar>
        <SideBar />
        <Box component="main" style={{ paddingBottom: '0px' }} sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Box id="content" style={{ padding: '0px' }} sx={{ flexGrow: 1, minHeight: (window.innerHeight - 130) }}>
            <Outlet />
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
}
