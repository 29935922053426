import {
  Grid, Alert,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import store from 'app/store';
import { get } from 'lodash';
import { getStates } from 'features/common/state/service';
import { EVALTYPE, EVALUATION_STATUS, PERMISSION } from 'common/Constants';
import PermissionRoute from 'app/router/PermissionRoute';
import { resetEvaluation } from '../state/reducer';
import { getById, getEvaluationResult, getNpiLinkageByEId, getMsaSuggestions } from '../state/service';
import EvaluationResult from './evaluationResult';
import EvaluationBasicInfo from './evaluationBasicInfo';
import StudyResults from './population/results-view/StudyResults';

export default function ViewEvaluation() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const evaluation = useSelector((state) => state.evaluation.evaluation);
  const results = useSelector((state) => state.evaluation.results);
  const evalId = get(evaluation, 'id');

  const getEvaluationInformation = () => {
    if (searchParams && searchParams.get('id')) {
      const evaId = searchParams.get('id');
      store.dispatch(getById(evaId));
      if (location.state?.evalType !== EVALTYPE.POPULATION) {
        store.dispatch(getEvaluationResult(evaId));
        store.dispatch(getNpiLinkageByEId(evaId));
      }
    } else {
      store.dispatch(resetEvaluation());
    }
  };

  useEffect(() => {
    store.dispatch(getStates());
    store.dispatch(getMsaSuggestions());
  }, []);

  useEffect(() => {
    getEvaluationInformation();
  }, [searchParams]);

  const renderResult = useMemo(() => {
    switch (evaluation.status) {
      case EVALUATION_STATUS.COMPLETED:
        if (evaluation.evalType === EVALTYPE.POPULATION) {
          return <StudyResults evalId={evalId} />;
        }
        return <EvaluationResult data={results} main={evaluation} />;
      case EVALUATION_STATUS.FAILED:
        return (
          <Grid item xs={12}>
            <Alert severity="error" variant="filled">
              Your evaluation failed to process. Please adjust your parameters and try again later.
            </Alert>
          </Grid>
        );
      default:
        return null;
    }
  }, [results, evaluation]);

  return (
    <PermissionRoute permission={evaluation.evalType === EVALTYPE.POPULATION ? PERMISSION.POPULATION_STUDY : PERMISSION.VIEW_INTELLIGENCE} key="study_view">
      <Grid container>
        {evalId
          && (
            <Grid container item spacing={2} rowSpacing={3}>
              <EvaluationBasicInfo evaluation={evaluation} />
              {renderResult}
            </Grid>
          )}
      </Grid>
    </PermissionRoute>
  );
}
