import React, { useEffect } from 'react';
import * as Constants from 'common/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSystemDate } from './state/service';

const textStyles = {
  fontSize: '12px',
  fontFamily: 'verdana, Helvetica, sans-serif',
  fontColor: Constants.TEXT_BLACK,
};

function Footer() {
  const dispatch = useDispatch();
  const systemDate = useSelector((state) => state.systemDate.systemDate);
  useEffect(() => {
    dispatch(fetchSystemDate());
  }, [dispatch]);

  const extraSpace = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';
  const copyRight = '\u00A9';
  const year = systemDate ? systemDate[0] : null;
  const amaYear = year - 1;
  return (
    <footer>
      <p style={textStyles}>
        {`${copyRight}${year} MultiPlan Inc. All Rights Reserved ${extraSpace}CPT ${copyRight} ${amaYear} American Medical Association.  All Rights Reserved.`}
      </p>
    </footer>
  );
}
export default Footer;
