import { Grid, Autocomplete, TextField, Divider, Stack } from '@mui/material';
import { DIVIDER_SOLID, VIEW_PLAN_BY_STATE, PR_STATE, VI_STATE, HOME_PAGE_TITLE_DARK } from 'common/Constants';
import { useSelector } from 'react-redux';
import React, { useEffect, useState, forwardRef } from 'react';
import store from 'app/store';
import { getStates } from 'features/common/state/service';
import { getStateNetworkRating } from 'features/evaluation/state/service';
import { HeaderRow, CustomHomePageTheme } from 'features/common/Style';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import SortIndicator from 'common/components/SortIndicator';
import TableCellHeader from 'common/components/TableCellHeader';
import TableRow from './tableRow';
import { setPrevSelectedState, setPrevSelectedStateSort } from '../state/reducer';

export default function ViewPlansByStateTable() {
  const stateOptions = useSelector((state) => state.provider.states);
  const [filteredStateOptions, setFilteredStateOptions] = useState([]);
  const [stateNetworkWithRating, setStateNetworkWithRating] = useState([]);
  const [viewStateData, setViewStateData] = useState([]);
  const prevSelectedState = useSelector((state) => state.home.prevSelectedState);
  const prevSelectedStateSort = useSelector((state) => state.home.prevSelectedStateSort);
  const statesToExclude = [PR_STATE, VI_STATE];
  const [sortConfig, setSortConfig] = useState({ key: 'label', direction: '' });
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    store.dispatch(setPrevSelectedStateSort({ key, direction }));
  };

  useEffect(() => {
    store.dispatch(getStates());
    if (prevSelectedState !== null) {
      getStateNetworkRating(prevSelectedState?.code).then((res) => {
        setStateNetworkWithRating(res);
      });
    }
    if (prevSelectedStateSort != null) {
      setSortConfig(prevSelectedStateSort);
    }
  }, []);

  useEffect(() => {
    setViewStateData(stateNetworkWithRating);
  }, [stateNetworkWithRating]);

  useEffect(() => {
    if (stateOptions != null && Array.isArray(stateOptions) && stateOptions.length > 0) {
      setFilteredStateOptions(stateOptions.filter((item) => item?.code
        && !statesToExclude.includes(item?.code)));
    }
  }, [stateOptions]);

  const sortedViewStateData = React.useMemo(() => {
    if (sortConfig.key === 'label' && sortConfig.direction === 'asc') {
      viewStateData.sort((a, b) => (a.label.localeCompare(b.label)));
    } else if (sortConfig.key === 'label' && sortConfig.direction === 'desc') {
      viewStateData.sort((a, b) => (b.label.localeCompare(a.label)));
    } else if (sortConfig.key === 'maxMsaScore' && sortConfig.direction === 'asc') {
      viewStateData.sort((a, b) => (a.maxMsaScore - b.maxMsaScore));
    } else if (sortConfig.key === 'maxMsaScore' && sortConfig.direction === 'desc') {
      viewStateData.sort((a, b) => (b.maxMsaScore - a.maxMsaScore));
    } else if (sortConfig.key === 'stateScore' && sortConfig.direction === 'asc') {
      viewStateData.sort((a, b) => (a.stateScore - b.stateScore));
    } else if (sortConfig.key === 'stateScore' && sortConfig.direction === 'desc') {
      viewStateData.sort((a, b) => (b.stateScore - a.stateScore));
    } else if (sortConfig.key === 'minMsaScore' && sortConfig.direction === 'asc') {
      viewStateData.sort((a, b) => (a.minMsaScore - b.minMsaScore));
    } else if (sortConfig.key === 'minMsaScore' && sortConfig.direction === 'desc') {
      viewStateData.sort((a, b) => (b.minMsaScore - a.minMsaScore));
    }
    return viewStateData;
  }, [viewStateData, sortConfig]);

  const customAppTheme = useTheme();
  const pageTheme = CustomHomePageTheme(customAppTheme);

  return (
    <>
      <Grid item xs={9}>
        <Autocomplete
          id="stateSelector"
          size="small"
          disableClearable
          fullWidth
          onChange={(_, data) => {
            getStateNetworkRating(data?.code).then((res) => {
              setStateNetworkWithRating(res);
              if (data?.code !== prevSelectedState?.code) {
                setSortConfig({ key: 'label', direction: 'asc' });
                store.dispatch(setPrevSelectedStateSort({ key: 'label', direction: 'asc' }));
              }
              store.dispatch(setPrevSelectedState(data));
            });
          }}
          isOptionEqualToValue={(opt, val) => opt.label === val.label}
          options={filteredStateOptions}
          value={prevSelectedState}
          renderInput={(inProps) => (
            <TextField
              placeholder="Select State"
              {...inProps}
              label="View State"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack>
          <Grid
            container
            sx={{ alignItems: 'flex-end', flexDirection: 'row', display: 'flex', flexWrap: 'nowrap', paddingTop: '1rem' }}
          >
            <Grid item xs={4.5}>
              <ThemeProvider theme={pageTheme}>
                <TableCellHeader
                  key="label"
                  label="Plan"
                  sortDirection={sortConfig.direction}
                  active={sortConfig.key === 'label'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('label')}
                  headerColor={HOME_PAGE_TITLE_DARK}
                  IconComponent={forwardRef((prop, ref) => <SortIndicator {...prop} ref={ref} height="24px" />)}
                  sx={{ alignItems: 'flex-end' }}
                />
              </ThemeProvider>
            </Grid>
            <Grid item xs={1.5}>
              <HeaderRow>Plan Type</HeaderRow>
            </Grid>
            <Grid item xs={2}>
              <ThemeProvider theme={pageTheme}>
                <TableCellHeader
                  key="minMsaScore"
                  label="Min MSA Rating"
                  sortDirection={sortConfig.direction}
                  active={sortConfig.key === 'minMsaScore'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('minMsaScore')}
                  headerColor={HOME_PAGE_TITLE_DARK}
                  headerAlign="right"
                  IconComponent={forwardRef((prop, ref) => <SortIndicator {...prop} ref={ref} marginRight="5px" height="24px" />)}
                  sx={{ alignItems: 'flex-end' }}
                  headerSX={{ width: '65px' }}
                />
              </ThemeProvider>
            </Grid>
            <Grid item xs={2}>
              <ThemeProvider theme={pageTheme}>
                <TableCellHeader
                  key="maxMsaScore"
                  label="Max MSA Rating"
                  sortDirection={sortConfig.direction}
                  active={sortConfig.key === 'maxMsaScore'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('maxMsaScore')}
                  headerColor={HOME_PAGE_TITLE_DARK}
                  IconComponent={forwardRef((prop, ref) => <SortIndicator {...prop} ref={ref} marginRight="5px" height="24px" />)}
                  sx={{ alignItems: 'flex-end' }}
                  headerSX={{ width: '65px' }}
                />
              </ThemeProvider>
            </Grid>
            <Grid item xs={2}>
              <ThemeProvider theme={pageTheme}>
                <TableCellHeader
                  key="stateScore"
                  label="Data Usability Rating"
                  sortDirection={sortConfig.direction}
                  active={sortConfig.key === 'stateScore'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('stateScore')}
                  headerColor={HOME_PAGE_TITLE_DARK}
                  IconComponent={forwardRef((prop, ref) => <SortIndicator {...prop} ref={ref} marginRight="5px" height="24px" />)}
                  sx={{ alignItems: 'flex-end' }}
                />
              </ThemeProvider>
            </Grid>
          </Grid>
          <Grid item>
            <Divider
              orientation="horizontal"
              sx={{
                height: '0.25rem',
                borderBottomWidth: '2px',
                borderBottomColor: DIVIDER_SOLID,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {sortedViewStateData.filter((n) => n !== null && n !== undefined).map((n, idx) => (
              <TableRow
                key={n.label}
                networkRowData={n}
                index={idx}
                dataLength={sortedViewStateData.length}
                selectedTab={VIEW_PLAN_BY_STATE}
              />
            ))}
          </Grid>
        </Stack>
      </Grid>
    </>
  );
}
