import CustomizedMaterialTable from 'common/components/CustomizedMaterialTable';
import * as Constants from 'common/Constants';
import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { decreaseFetchingQueue, updateSystemErrorMsg } from 'app/state/reducer';
import { MAIN_HOST } from 'common/AppConstant';
import store from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { convertTimestamp, formatBytes } from 'common/util/commonUtil';
import { grey } from '@mui/material/colors';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
// import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Tooltip } from '@mui/material';
// disable for 07/28/2023 release
import { DeleteForever } from '@mui/icons-material';
import { confirmDialog } from 'common/components/ConfirmationDialog';
import { getMenuCounts } from 'features/count/state/service';
import { deleteDownloadById, getFileDownloadUrl, trackDownload } from './state/service';

export default function Download() {
  const tableRef = useRef();
  const dispatch = useDispatch();

  const refreshFlag = useSelector((state) => state.download.refreshFlag);

  const [shouldRefresh, setShouldRefresh] = useState(false);

  const download = async (id) => {
    let errorOccured = false;
    try {
      const response = await store.dispatch(getFileDownloadUrl(id));
      const blob = new Blob([response?.payload.data], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response?.payload.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      errorOccured = true;
      dispatch(updateSystemErrorMsg(error?.message));
    } finally {
      if (!errorOccured) {
        store.dispatch(trackDownload(id));
      }
    }
  };

  // disable for 07/28/2023 release
  // const regenerate = (id) => {
  //   store.dispatch(regenerateFile(id));
  // };

  const refresh = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const getStatusDisplayName = (code) => {
    if (code && code in Constants.REQUIRED_FILE_STATUS) {
      return Constants.REQUIRED_FILE_STATUS[code].displayName;
    }
    return null;
  };

  const handleDeleteFile = (id) => {
    const confimrationMessage = 'Are you sure you want to delete this file download?';
    confirmDialog(() => store.dispatch(deleteDownloadById(id)), confimrationMessage);
  };

  const updateExportSearchCount = () => {
    const fetchCriteria = {
      fetchEvaluationCount: false,
      fetchSearchCount: true,
      fetchWeightsCount: false,
    };
    store.dispatch(getMenuCounts(fetchCriteria));
  };
  useEffect(() => {
    refresh();
    updateExportSearchCount();
  }, [shouldRefresh, refreshFlag]);

  const columns = [
    {
      title: 'Name',
      field: 'fileName',
      render: (rd) => rd.customName ?? rd.fileName,
    },
    {
      title: 'Size',
      field: 'size',
      width: '150px',
      render: (rd) => formatBytes(rd?.size),
    },
    {
      title: 'Status',
      field: 'statusId',
      width: '150px',
      render: (rowData) => (
        <Tooltip title={getStatusDisplayName(rowData?.fileStatus?.status)}>
          <Box>
            {rowData?.fileStatus?.status === Constants.REQUIRED_FILE_STATUS.CREATED.code && (
              <HourglassEmptyIcon />
            )}
            {rowData?.fileStatus?.status === Constants.REQUIRED_FILE_STATUS.IN_PROGRESS.code && (
              <HourglassEmptyIcon sx={{ color: 'pending.main' }} />
            )}
            {rowData?.fileStatus?.status === Constants.REQUIRED_FILE_STATUS.COMPLETED.code && (
              <CheckCircleOutlineIcon color="success" />
            )}
            {rowData?.fileStatus?.status === Constants.REQUIRED_FILE_STATUS.ERROR.code && (
              <ErrorOutlineIcon color="error" />
            )}
          </Box>
        </Tooltip>
      ),
    },
    {
      title: 'Created at',
      field: 'createdAt',
      width: '300px',
      render: (rd) => convertTimestamp(rd.createdAt),
    },
    {
      title: 'Updated at',
      field: 'updatedAt',
      width: '300px',
      render: (rd) => convertTimestamp(rd.updatedAt),
    },
  ];

  return (
    <CustomizedMaterialTable
      options={{
        pageSize: 10,
        debounceInterval: 400,
        actionsColumnIndex: -1,
        rowStyle: {
          backgroundColor: grey[100],
        },
      }}
      tableRef={tableRef}
      columns={columns}
      title="Exported Searches"
      data={(query) =>
        new Promise((resolve) => {
          const request = {
            page: query.page,
            size: query.pageSize,
            searchTerm: query.search,
            sortProperty: Array.isArray(query.orderByCollection)
              && query.orderByCollection.length === 1
              ? columns[query.orderByCollection[0].orderBy].field
              : 'createdAt',
            direction: Array.isArray(query.orderByCollection)
              && query.orderByCollection.length === 1
              && query.orderByCollection[0].orderDirection
              ? query.orderByCollection[0].orderDirection.toUpperCase()
              : Constants.DIRECTION_DESC,
          };
          axios
            .post(`${MAIN_HOST}/required-file/search`, request)
            .then((m) => {
              const { data } = m;
              resolve({
                data: data.content,
                page: data.number,
                size: data.size,
                totalCount: data.totalElements,
              });
            })
            .catch(() => {
              resolve({
                data: [],
                page: 0,
                totalCount: 0,
              });
              store.dispatch(decreaseFetchingQueue());
            });
        })
      }
      actions={[
        (data) => ({
          position: 'row',
          icon: () => <DownloadIcon />,
          disabled: !(data?.fileStatus?.status
            === Constants.REQUIRED_FILE_STATUS.COMPLETED.code),
          tooltip: 'Download',
          onClick: (_, rowData) => {
            download(rowData.id);
          },
        }),
        // disable for 07/28/2023 release
        // (data) => ({
        //   position: 'row',
        //   icon: () => <ReplayCircleFilledIcon />,
        //   disabled: (data?.fileStatus?.status
        //     !== Constants.REQUIRED_FILE_STATUS.ERROR.code)
        //     && (data?.fileStatus?.status
        //       !== Constants.REQUIRED_FILE_STATUS.COMPLETED.code),
        //   tooltip: 'Regenerate',
        //   onClick: (_, rowData) => regenerate(rowData.id),
        // }),
        {
          icon: () => <RefreshIcon />,
          tooltip: 'Refresh Data',
          onClick: () => {
            setShouldRefresh(!shouldRefresh);
          },
          isFreeAction: true,
        },
        {
          icon: () => <DeleteForever />,
          tooltip: 'Delete',
          onClick: (_, rowData) => {
            handleDeleteFile(rowData?.id);
          },
        },
      ]}
    />
  );
}
