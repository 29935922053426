import React, { useState, useEffect } from 'react';
import {
  Tab,
  Box,
  // Button,
  Divider,
  Grid,
  Paper,
  Stack,
} from '@mui/material';
import { BoxHeaderStyled, BoxStyled, TabsStyled } from './TabStyles';
import TableHeader from './tableHeader';
import TableRow from './tableRow';
import useCustomSort from '../hooks/useCustomSort';

export default function ResultDetail({ rollupData, totalDetails }) {
  const [selectedTab, setSelectedTab] = useState('institutional');
  // const [showLegendModal, setShowLegendModal] = useState(false);
  // const [showWeightExpModal, setShowWeightExpModal] = useState(false);
  const [expandBitmap, setExpandBitmap] = useState([]);
  const selectedTabdataArray = rollupData.filter((item) => item.billingClass === selectedTab);

  const {
    sortedData,
    handleSortState,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    activeSort,
    setActiveSort,
  } = useCustomSort({ initialData: selectedTabdataArray, shouldSort: selectedTab });

  const handleBitmapChange = (index, val) => {
    const newBitmap = [...expandBitmap];
    newBitmap[index] = val;
    setExpandBitmap(newBitmap);
  };

  const resetBitmap = () => {
    setExpandBitmap(Array(rollupData.length).fill(false));
  };

  useEffect(() => {
    resetBitmap();
  }, [sortedData]);

  useEffect(() => {
    resetBitmap();
    setOrderBy('idealPercent');
    setOrder('desc');
    setActiveSort(true);
  }, [selectedTab]);

  const renderHeader = () => (
    <BoxHeaderStyled display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        justifyContent="space-between"
      >
        <TabsStyled
          value={selectedTab}
          onChange={(_e, val) => setSelectedTab(val)}
          textColor="secondary"
        >
          <Tab label="Institutional" value="institutional" />
          <Tab label="Professional" value="professional" />
        </TabsStyled>
        <BoxStyled
          alignSelf="end"
          display="flex"
          flexDirection="row"
        >
          {/* <Button
            variant="contained"
            onClick={() => setShowLegendModal(true)}
            size="small"
          >
            Score legend
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowWeightExpModal(true)}
          >
            Weight explanation
          </Button> */}
        </BoxStyled>
      </Box>
      <Divider />
    </BoxHeaderStyled>
  );

  const renderTable = () => (
    <Paper elevation={0}>
      <Stack>
        <TableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleSortState}
          active={activeSort}
        />
        {sortedData?.length > 0 ? sortedData
          .map((item, idx) => (
            <TableRow
              key={item.id}
              data={item}
              showDetails={expandBitmap[idx]}
              selectedTab={selectedTab}
              handleShowDetails={(val) => {
                handleBitmapChange(idx, val);
              }}
              totalDetails={totalDetails}
        />
          )) : (
            <p style={{
              textAlign: 'center',
              fontSize: '0.875rem',
              paddingTop: '5px' }}>
              No records to display
            </p>
        )
      }
      </Stack>
    </Paper>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column">
          {renderHeader()}
          {renderTable()}
        </Box>
      </Grid>
    </Grid>
  );
}
