import React from 'react';
import { Typography, TableSortLabel, TableCell } from '@mui/material';

export default function TableCellHeader({
  key,
  label,
  sortDirection,
  active,
  direction,
  onClick,
  IconComponent,
  headerColor,
  headerAlign,
  headerSX,
  sx,
}) {
  return (
    <TableCell
      key={key}
      sortDirection={sortDirection}
      align="left"
      padding="none"
      sx={{ borderBottom: 'none' }}
    >
      <TableSortLabel
        active={active}
        direction={direction}
        onClick={onClick}
        sx={sx}
        IconComponent={IconComponent}
      >
        <Typography variant="h6" color={headerColor} align={headerAlign} sx={headerSX}>{label}</Typography>
      </TableSortLabel>
    </TableCell>
  );
}
